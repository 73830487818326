import React, {useEffect, useState} from "react";
import axios from "axios";
import {useTranslation} from "react-i18next";
import arrowDown from "../assets/icons/arrowDown.svg";
import moment from "moment";
import {BarLoader, CircleLoader} from "react-spinners";
import {css} from "@emotion/react";

const ServizioBus = ({dataServizioBus, cardActive, serviziAttivati, information, totaleServiziAttivati}) => {

    const { t } = useTranslation();

    const [cardName, setCardName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [cards, setCards] = useState([]);
    const [numeroCorsa, setNumeroCorsa] = useState();
    const [totalActivations, setTotalActivations] = useState();

    const [messageInfo, setMessageInfo] = useState('');
    const [messageDanger, setMessageDanger] = useState('');
    const [messageSuccess, setMessageSuccess] = useState('');

    const [dataServiceBus, setDataServiceBus] = useState([]);
    const [selectedServiceBus, setSelectedServiceBus] = useState(null);
    const [isServiceBusOpen, setIsServiceBusOpen] = useState(false);
    const [ServiceBusHeight, setServiceBusHeight] = useState("0");
    const [ServiceBusTop, setServiceBusTop] = useState("[540px]");
    const [ServiceBusPaddingTop, setServiceBusPaddingTop] = useState("0");
    const [ServiceBusPaddingBottom, setServiceBusPaddingBottom] = useState("0");

    const [ servizioInformation , setServizioInformtion] = useState([]);
    const [code, setCode] = useState([]); // Array per memorizzare i singoli numeri del codice
    const [showConfirmation, setShowConfirmation] = useState(false);

    const [serviziAttivatiNew, setServiziAttivati] = useState(serviziAttivati);

    const [serviceActiveCardInformationNew, setServiceActiveCardInfomationNew] = useState([]);

    useEffect(() => {
        setServiceActiveCardInfomationNew([information]);
        // console.log(serviceActiveCardInformationNew[0])
        // console.log(information)
        // console.log(serviziAttivati)
        setServiziAttivati(serviziAttivati);
    }, [information]);


    //set code
    const [fanoCardCode, setFanoCardCode] = useState('');
    const [titolare, setTitolare] = useState('');
    const [email, setEmail] = useState('');
    const [provenienza, setProvenienza] = useState('');
    useEffect(() => {
        // console.log("Data in useEffect:", dataServizioBus);

        let newFanoCardCode = ""; // Variabile temporanea per il nuovo codice

        if (dataServizioBus.precode) {
            newFanoCardCode = dataServizioBus.precode;
            // console.log("Setting precode:", dataServizioBus.precode);
        } else if (dataServizioBus.fanocard) {
            newFanoCardCode = dataServizioBus.fanocard;
            // console.log("Setting fanocard:", dataServizioBus.fanocard);
        } else if (dataServizioBus.tiketid) {
            if (dataServizioBus.precode) {
                newFanoCardCode = dataServizioBus.precode;
                // console.log("Setting precode:", dataServizioBus.precode);
            } else if (dataServizioBus.fanocard) {
                newFanoCardCode = dataServizioBus.fanocard;
                // console.log("Setting fanocard:", dataServizioBus.fanocard);
            } else {
                newFanoCardCode = dataServizioBus.tiketid;
                // console.log("Setting tiketid:", dataServizioBus.tiketid);

            }
        }
        if (dataServizioBus.precode) {
            newFanoCardCode = dataServizioBus.precode;
            //console.log("Setting precode:", dataServizioBus.precode);
        }
        setFanoCardCode(newFanoCardCode); // Imposta il valore di fanoCardCode

        //console.log("Fanocard code after setFanoCardCode:", newFanoCardCode); // Mostra il valore aggiornato di fanoCardCode

        setTitolare(dataServizioBus.titolare);
        setEmail(dataServizioBus.email);
        setProvenienza(dataServizioBus.provenienza);
    }, [dataServizioBus]);



    // Funzione per gestire il cambiamento di valore in un input
    const handleChange = (e) => {
        const { value } = e.target;
        // Aggiorna il valore di code utilizzando setCode e utilizza una funzione di callback
        setCode(value);
        // Il console.log qui verrà eseguito prima che code sia stato aggiornato
        console.log('Value:', value);
        // Rimuovi questo console.log se non vuoi che venga visualizzato prima dell'aggiornamento di code
        document.querySelector(`.verification__input`).focus();
    };

// Funzione per controllare se tutti gli input sono stati compilati



// Funzione per gestire il submit del form
    const handleConfirmation = () => {
        // Qui puoi gestire l'invio del codice al backend o fare qualsiasi altra operazione necessaria
        // setShowConfirmation(false); // Nascondi il popup di conferma dopo l'invio
        // setShowConfirmation(false); // Nascondi il popup di conferma dopo l'invio
        setIsLoading(true); // Imposta isLoading su true prima di avviare la chiamata API

        handleClick(fanoCardCode);
        setTimeout(() => {
            toggleServiceBus();
            setCode([]); // Resetta il valore di code
            setServiziAttivati(1);
            setIsLoading(false); // Imposta isLoading su false dopo aver completato l'operazione asincrona
        }, 3000);
    };




    const totalBusServiceActive = async () => {
        setIsLoading(true);
        setIsError(false);

        let activeDataValue;

        // Determina quale dato è attivo e assegna il valore corrispondente

        activeDataValue = fanoCardCode
        const auth = {
            username: 'rest.serviziobus',
            password: 'vGhQdQq!WtnVCh5nLgJ6fXP!'
        };

        const headers = {
            'Authorization': `Basic ${btoa(`${auth.username}:${auth.password}`)}`,
            'test': 'test'
        };
        try {
            const response = await axios.get(`https://dev.statistiche.fano.bbsitalia.com/new-api/servizio-bus/check-total/${activeDataValue}?_format=json`, {headers});
            const data = response.data;
            setTotalActivations(data.length);
        } catch (error) {
            console.error('Error:', error);
            setIsError(true);
        }
        setIsLoading(false);
    };


    // Funzione per gestire l'apertura e la chiusura del modale
    function toggleServiceBusHeight() {
        setServiceBusHeight(ServiceBusHeight === "0" ? "55vh" : "0");
        setServiceBusTop(ServiceBusTop === "[580px]" ? "0" : "[580px]");
        setServiceBusPaddingTop(ServiceBusPaddingTop === "0" ? "4" : "0");
        setServiceBusPaddingBottom(ServiceBusPaddingBottom === "0" ? "4" : "0");
    }

    // Funzione per gestire l'apertura e la chiusura del modale e per resettare i messaggi
    const toggleServiceBus = () => {

        setIsServiceBusOpen(!isServiceBusOpen);
        toggleServiceBusHeight();

        // Resetta i messaggi

        setCode([]);
        setMessageInfo('');
        setMessageDanger('');
        setMessageSuccess('');

        // Effettua una richiesta GET per ottenere quante attivazioni ha fatto una determinata card
        totalBusServiceActive();
    };





    // Funzione per gestire il click su una card e per ottenere le informazioni della card selezionata e per ottenere il numero di attivazioni della card selezionata
    const handleClick = async (cardName) => {
        try {
            setIsLoading(true);
            setIsError(false);

            const auth = {
                username: 'rest.serviziobus',
                password: 'vGhQdQq!WtnVCh5nLgJ6fXP!'
            };

            const headers = {
                'Authorization': `Basic ${btoa(`${auth.username}:${auth.password}`)}`,
                'test': 'test'
            };

            const checkCountUrl = `https://dev.statistiche.fano.bbsitalia.com/new-api/servizio-bus/check-count/${cardName}`;
            const createNodeUrl = 'https://dev.statistiche.fano.bbsitalia.com/node?_format=json';

            const response = await axios.get(checkCountUrl, { headers });
            const data = response.data;

            setServizioInformtion(data);

            if (data.length === 0) {
                await axios.post(createNodeUrl, {
                    title: [{ value: cardName }],
                    // field_numero_corsa: [{ value: numeroCorsa }],
                    field_nome_e_cognome: [{ value: titolare }],
                    field_email: [{ value: email }],
                    field_provenienza: [{ value: provenienza }],
                    type: [{ target_id: "servizio_bus" }]
                }, { headers });

                const secondResponse = await axios.get(checkCountUrl, { headers });
                const secondData = secondResponse.data;

                if (secondData.length >= 1) {
                    console.log('Card created successfully');
                    setMessageSuccess('Servizio bus attivato con successo');

                    const response3 = await axios.get(`https://dev.statistiche.fano.bbsitalia.com/api/servizio-bus/${cardName}`, { headers });
                    const data3 = response3.data;
                    setServiceActiveCardInfomationNew([data3[0]]);
                    console.log(data3);

                    if (response3.data.length >= 1) {
                        setServiziAttivati(1);
                    }
                }

                setCards(secondData);
            } else {
                setCards(data);
                console.log('Card already created');
                setMessageDanger('Servizio bus giá attivo');
            }
        } catch (error) {
            console.error('Error:', error);
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        // Effettua una richiesta GET per ottenere quante attivazioni ha fatto una determinata card
        totalBusServiceActive();

    }, [cardName]);


    const [countdown, setCountdown] = useState("");
    const [progress, setProgress] = useState(0);
    useEffect(() => {
        if (!serviceActiveCardInformationNew[0] || !dataServizioBus.data_fine) {
            // Information is undefined, handle the case here
            return;
        }

        const { field_data_attivazione } = serviceActiveCardInformationNew[0];
        const endTime = moment(dataServizioBus.data_fine).endOf('day'); // Imposta la data fine al termine della giornata
        const dateTime = moment(field_data_attivazione);
        const countdownTime = endTime; // Usa l'ora di fine per il countdown

        const updateCountdown = () => {
            const now = moment();
            const duration = moment.duration(countdownTime.diff(now));
            const days = duration.days();
            const hours = duration.hours();
            const minutes = duration.minutes();
            const seconds = duration.seconds();

            const formattedCountdown = `${days} ${t("giorni")}, ${hours} ${t("ore")}, ${minutes} ${t("minuti")}, ${seconds} ${t("secondi")}`;
            setCountdown(formattedCountdown);

            // Debug values
            //console.log('Now:', now.format("HH:mm:ss"));
            //console.log('End Time:', endTime.format("HH:mm:ss"));
            //console.log('Duration:', duration.humanize());
            //console.log('Formatted Countdown:', formattedCountdown);
        };

        const updateProgressBar = () => {
            const now = moment();
            const duration = moment.duration(countdownTime.diff(now));
            const remainingSeconds = duration.asSeconds();
            const totalDuration = moment.duration(countdownTime.diff(dateTime)).asSeconds();
            const progressPercentage = 100 - ((totalDuration - remainingSeconds) / totalDuration) * 100;

            setProgress(progressPercentage);
            if (progressPercentage <= 0) {
                setServiziAttivati(0);
            }

            // Debug values
            //console.log('Progress Percentage:', progressPercentage);
        };

        const intervalProgress = setInterval(updateProgressBar, 1000);
        const interval = setInterval(updateCountdown, 1000);

        return () => {
            clearInterval(interval);
            clearInterval(intervalProgress);
        };
    }, [serviceActiveCardInformationNew[0], dataServizioBus.data_fine, t]);


    //
    // if (!serviceActiveCardInformationNew[0]) {
    //     return null; // or some loading indicator
    // }
    //
    // const { field_data_attivazione } = serviceActiveCardInformationNew[0];
    // const formattedActivationDate = moment(field_data_attivazione).format("DD-MM-YYYY");
    // const formattedActivationTime = moment(field_data_attivazione).format("HH:mm:ss");
    // const formattedEndDate = moment(dataServizioBus.data_fine).format("DD-MM-YYYY");
    // const formattedEndTime = moment(dataServizioBus.data_fine).format("HH:mm:ss");
    //


    // const serviceInformation = () => {
    //
    //     axios.get(`https://dev.statistiche.fano.bbsitalia.com/api/servizio-bus/${dataServizioBus.precode || dataServizioBus.tiketid || dataServizioBus.fanocard}`)
    //         // axios.get(`https://dev.statistiche.fano.bbsitalia.com/api/servizio-bus/${cardName}`)
    //         .then(response => {
    //             setServiceActiveCardInfomationNew(response.data)
    //             console.log(serviceActiveCardInformationNew[0])
    //
    //             if(response.data.length >= 1){
    //                 setServiziAttivati(1);
    //             }
    //         })
    //         .catch(error => console.log(error));
    //
    // }




    const serviceInformation = async (fanoCardCode) => {
        // Resetta i messaggi
        setIsLoading(true);
        setIsError(false);
        // Imposta le credenziali per l'autenticazione
        const auth = {
            username: 'rest.serviziobus',
            password: 'vGhQdQq!WtnVCh5nLgJ6fXP!'
        };
        // Imposta le intestazioni per l'autenticazione
        const headers = {
            'Authorization': `Basic ${btoa(`${auth.username}:${auth.password}`)}`
        };

        // Effettua una richiesta GET per verificare se ci sono oggetti con il nome della carta
        try {
            // Effettua una richiesta GET per verificare se ci sono oggetti con il nome della carta
            const response = await axios.get(`https://dev.statistiche.fano.bbsitalia.com/new-api/servizio-bus/${fanoCardCode}`);
            const data = response.data;
            // Imposta lo stato con i dati ottenuti dalla richiesta GET
            setServiceActiveCardInfomationNew(data)
            console.log(serviceActiveCardInformationNew[0])

            if(response.data.length >= 1){
                setServiziAttivati(1);
            }
        } catch (error) {
            console.error('Error:', error);
            setIsError(true);
        }

        setIsLoading(false);
    };
    const override = css`
      display: block;
      margin: 0 auto;
      border-color: red;
    `;

    return (
        <>
            <>
                {cardActive}

                {serviziAttivatiNew >= 1 ? (
                    <>
                        {isLoading ? (
                            <div className="">
                                <BarLoader color={"#2b6cb0"} css={override} size={50}/>
                            </div>
                        ) : (
                            <div className={'flex justify-between flex-wrap'}>
                                <div className={'flex flex-wrap justify-between w-full'}>
                                    <p className={' mb-2 text-sm'}>{t('serviziobus60min')}</p>
                                    {/*<p className={'mt-2 mb-2 text-sm'}>60 min</p>*/}
                                    <span className={`relative px-3 h-6 text-[14px] rounded-2xl text-white bg-green-500`}>
                                    {t('attiva')}
                                </span>
                                </div>
                                <div className={'w-full rounded-[5px]'}>
                                    <div className={'flex flex-wrap justify-between'}>
                                        <p className={'mt-2 mb-2 text-sm '}>{t('attivatoil')}</p>
                                        {serviceActiveCardInformationNew[0] && serviceActiveCardInformationNew[0].field_data_attivazione && (

                                            <p className={'mt-2 mb-2 text-sm '}>{moment(serviceActiveCardInformationNew[0].field_data_attivazione).format("DD-MM-YYYY")}</p>
                                        )}
                                        {/*<p>{t('checkData')} {serviceActiveCardInformationNew[0].field_data_attivazione}</p>*/}

                                    </div>
                                    <div className={'flex flex-wrap justify-between'}>
                                        <p className={'mt-2 mb-2 text-sm '}>{t('alleore')}</p>
                                        {serviceActiveCardInformationNew[0] && serviceActiveCardInformationNew[0].field_data_attivazione && (

                                            <p className={'mt-2 mb-2 text-sm '}>{moment(serviceActiveCardInformationNew[0].field_data_attivazione).format("HH:mm:ss")}</p>
                                        )}
                                        {/*<p>{t('checkData')} {serviceActiveCardInformationNew[0].field_data_attivazione}</p>*/}
                                    </div>
                                    <div className={'flex flex-wrap justify-between'}>
                                        {/*<p className={'mt-2 mb-2 text-sm '}>{t('attivacorsa')}</p>*/}
                                        {/*{serviceActiveCardInformationNew[0] && serviceActiveCardInformationNew[0].field_numero_corsa && (*/}
                                        {/*    <p className={'mt-2 mb-2 text-sm uppercase'}>{serviceActiveCardInformationNew[0].field_numero_corsa}</p>*/}
                                        {/*)}*/}
                                        {/*<p>{t('checkData')} {serviceActiveCardInformationNew[0].field_data_attivazione}</p>*/}

                                    </div>
                                    <div className="w-full h-4 bg-gray-300 rounded-full w-full mt-2 text-center mb-2 overflow-hidden">
                                        <div
                                            className={`h-4 bg-fanoBlue rounded-full w-full`}
                                            style={{
                                                width: `${progress}%`,
                                            }}
                                        ></div>
                                    </div>
                                    <div className={'flex flex-wrap text-center w-full'}>
                                        <p className={'text-sm mt-1'}>{countdown}</p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>

                ) : (
                    <>
                        {isLoading ? (
                            <div className="">
                                <BarLoader color={"#2b6cb0"} css={override} size={50}/>
                            </div>
                        ) : (
                            <div className={'flex justify-between'}>
                                <p className={'mt-2 mb-2 text-sm'}>{t('serviziobusnonattivo')}</p>
                                <button
                                    onClick={handleConfirmation}
                                    disabled={!cardActive}
                                    className={`text-sm bg-fanoBlue text-white py-1 rounded-md font-medium px-3
                        ${
                                        cardActive ? "opacity-100" : "opacity-50 cursor-not-allowed"
                                    }
                        `}>
                                    {t('attivaserviziobus')}
                                </button>

                                {/*<button*/}
                                {/*    onClick={handleConfirmation}*/}
                                {/*    className="bg-fanoBlue text-white px-4 py-2 rounded-md"*/}
                                {/*>*/}
                                {/*    {t('conferma')}*/}
                                {/*</button>*/}
                            </div>

                        )}
                    </>

                )}

                {servizioInformation && (
                    <div>
                        {/*<p>{t('iniziaunanuovacorsa')} {totalActivations}/5</p>*/}
                        {/*<p>{t('codiceserviziobus')} {fanoCardCode}</p>*/}
                        {/*<p>{t('checkData')} {checkActive[0].field_data_attivazione}</p>*/}
                    </div>
                )}

                {/*<div className={`${isServiceBusOpen ? "backdrop:blur-3xl backdrop-brightness-50 backdrop-filter fixed top-0 left-0 w-full h-full z-[999999999999999] flex justify-center items-center text-center opacity-1" : "backdrop:blur-3xl backdrop-brightness-50 backdrop-filter fixed top-0 left-0 w-full z-[9999] flex justify-center items-center opacity-0"} `}>*/}
                {/*    /!*<div className={`${isServiceBusOpen ? "backdrop:blur-3xl backdrop-brightness-50 backdrop-filter fixed top-0 l1eft-0 w-full h-full z-[9999] flex justify-center items-center" : "opacity-0"} `} onClick={toggleServiceBus}>*!/*/}
                {/*    <div*/}
                {/*        className={`${*/}
                {/*            isServiceBusOpen ? "" : "hidden"*/}
                {/*        } fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-[9999]`}*/}
                {/*        id="my-modal"*/}
                {/*        onClick={toggleServiceBus}*/}
                {/*    ></div>*/}
                {/*    <div className={`${isServiceBusOpen ? "bg-white " : "bg-fanoBlue delay-300"} absolute w-full pb-10 bottom-0 overflow-scroll  b-t-l-r-15 pl-5 pr-5 z-[9999]`}>*/}
                {/*        <div className={`h-${ServiceBusHeight} top-${ServiceBusTop} z-10 relative duration-300 pt-${ServiceBusPaddingTop} pb-${ServiceBusPaddingBottom} w-full`}>*/}
                {/*            <div className="px-1 flex flex-wrap items-center mb-6 mt-3 w-full">*/}
                {/*                <img src={arrowDown} className="ml-1 mr-3 -rotate-90 cursor-pointer" onClick={toggleServiceBus} />*/}
                {/*                <p className="font-semibold">*/}
                {/*                    /!*{selectedCard.sottotipo}*!/*/}
                {/*                    {t('iniziaunanuovacorsa')}*/}
                {/*                </p>*/}
                {/*            </div>*/}

                {/*            /!*<p>Utilizzi massimi {totalActivations}/5</p>*!/*/}
                {/*            <form onSubmit={handleSubmit}>*/}
                {/*                /!*{code.map((digit, index) => (*!/*/}
                {/*                <input*/}
                {/*                    type="text"*/}
                {/*                    // inputMode="numeric" // Imposta la tastiera numerica*/}
                {/*                    className="verification__input verification__input--4"*/}
                {/*                    placeholder={"-"}*/}
                {/*                    onChange={handleChange} // Passa la funzione handleChange direttamente*/}
                {/*                    maxLength="4"*/}
                {/*                    minLength="1"*/}
                {/*                    style={{ width: '160px', marginRight: '5px' }}*/}
                {/*                />*/}
                {/*                /!*))}*!/*/}

                {/*                <button*/}
                {/*                    // onClick={handleButtonClick}*/}
                {/*                    disabled={code.length <= 0} // Disabilita il pulsante solo se la lunghezza del codice è maggiore di 1*/}
                {/*                    className={`w-full bg-fanoBlue mt-5 text-white px-3 py-2 rounded-md font-medium h-[50px] rounded-[10px] w-full ${code.length <= 0 ? 'opacity-50 cursor-not-allowed' : ''}`}*/}
                {/*                >*/}
                {/*                    {t('attivacorsa')}*/}
                {/*                </button>*/}


                {/*                {showConfirmation && (*/}
                {/*                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">*/}
                {/*                        <div className="bg-white p-8 rounded-lg">*/}
                {/*                            <p>{t('confermiilcodicecorsainserito')}</p>*/}
                {/*                            <p>{t('codice')} {code}</p>*/}
                {/*                            <div className="flex justify-end mt-4">*/}
                {/*                                <button*/}
                {/*                                    onClick={handleConfirmation}*/}
                {/*                                    className="bg-fanoBlue text-white px-4 py-2 rounded-md"*/}
                {/*                                >*/}
                {/*                                    {t('conferma')}*/}
                {/*                                </button>*/}
                {/*                            </div>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                )}*/}

                {/*                {messageInfo && (*/}
                {/*                    <div*/}
                {/*                        className="mt-4 p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-blue-500 dark:text-white"*/}
                {/*                        role="alert">*/}
                {/*                        {messageInfo}*/}
                {/*                    </div>*/}
                {/*                )}*/}

                {/*                {messageDanger && (*/}
                {/*                    <div className="mt-4 p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-red-800 dark:text-white"*/}
                {/*                         role="alert">*/}
                {/*                        {messageDanger}*/}
                {/*                    </div>*/}
                {/*                )}*/}

                {/*                {messageSuccess && (*/}
                {/*                    <div*/}
                {/*                        className="mt-4 p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-green-800 dark:text-white"*/}
                {/*                        role="alert">*/}
                {/*                        {messageSuccess}*/}
                {/*                    </div>*/}
                {/*                )}*/}
                {/*            </form>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </>
        </>

    );
}

export default ServizioBus;
