import React, { useEffect, useState } from "react";
import fanoCard7gg from "../assets/FanoCard7gg.jpg";
import QRCode from "qrcode.react";
import arrowDown from "../assets/icons/arrowDown.svg";
import fanoCard14gg from "../assets/FanoCard14gg.jpg";
import {useTranslation} from "react-i18next";
import axios from "axios";
import ServizioBus from "./ServizioBus";


export const FanoCard7gg = (props) => {

    const { t } = useTranslation();

    const [dataCard, setDataCard] = useState([]);
    const [selectedCard, setSelectedCard] = useState(null);
    const [isCardOpen, setIsCardOpen] = useState(false);
    const [cardHeight, setCardHeight] = useState("0");
    const [cardTop, setCardTop] = useState("[540px]");
    const [cardPaddingTop, setCardPaddingTop] = useState("0");
    const [cardPaddingBottom, setCardPaddingBottom] = useState("0");

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const filteredData = props.data.filter((item) => item.status === true);
        setDataCard(filteredData);
        // console.log(filteredData);
    }, [props.data]);

    const today = new Date();

    const handleCardClick = (card) => {
        setSelectedCard(card);
        toggleCard();
        totalBusServiceActive(card)
        activeCardInformation(card)
    };

    const closePopup = () => {
        setSelectedCard(null);
    };

    const deleteCard = (precode, tiketid, fanocard) => {
        const updatedData = dataCard.filter((card) => card.tiketid !== selectedCard.tiketid);
        setDataCard(updatedData);
        setSelectedCard(null);
        deleteLocalStorage(precode);
        deleteLocalStorage(tiketid);
        deleteLocalStorage(fanocard);

        if (updatedData.length === 0) {
            window.location.reload();
        }
    };

    const deleteLocalStorage = (precode, tiketid, fanocard) => {
        localStorage.removeItem(`qrCode-${precode}`);
        localStorage.removeItem(`qrCode-${tiketid}`);
        localStorage.removeItem(`qrCode-${fanocard}`);
    };

    const filteredDataCard = dataCard.filter((item) => {
        const startDate = new Date(
            item.data_inizio.substring(0, 4),
            item.data_inizio.substring(4, 6) - 1,
            item.data_inizio.substring(6)
        );
        const endDate = new Date(
            item.data_fine.substring(0, 4),
            item.data_fine.substring(4, 6) - 1,
            item.data_fine.substring(6)
        );

        const daysUntilActivation = Math.ceil((startDate - today) / (1000 * 60 * 60 * 24));
        const activationText = daysUntilActivation === 0 ? `${t('oggi')}` : `${t('siattiveratra')} ${daysUntilActivation} ${t('giorni')}`;

        if (daysUntilActivation >= 0 && daysUntilActivation <= 30) {
            item.activationText = activationText;
            return item.id_tipologia === "57" || item.id_tipologia === "58";
        }

        return false;
    });

    filteredDataCard.sort((a, b) => {
        const startDateA = new Date(
            a.data_inizio.substring(0, 4),
            a.data_inizio.substring(4, 6) - 1,
            a.data_inizio.substring(6)
        );
        const endDateA = new Date(
            a.data_fine.substring(0, 4),
            a.data_fine.substring(4, 6) - 1,
            a.data_fine.substring(6)
        );
        const isActiveA = today >= startDateA && today <= endDateA;

        const startDateB = new Date(
            b.data_inizio.substring(0, 4),
            b.data_inizio.substring(4, 6) - 1,
            b.data_inizio.substring(6)
        );
        const endDateB = new Date(
            b.data_fine.substring(0, 4),
            b.data_fine.substring(4, 6) - 1,
            b.data_fine.substring(6)
        );
        const isActiveB = today >= startDateB && today <= endDateB;

        if (isActiveA && !isActiveB) {
            return -1;
        }
        if (!isActiveA && isActiveB) {
            return 1;
        }
        return 0;
    });

    function formatDate(dateString) {
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6);
        const day = dateString.substring(6);
        return `${day}/${month}/${year}`;
    }

    function toggleCardHeight() {
        setCardHeight(cardHeight === "0" ? "full" : "0");
        setCardTop(cardTop === "[580px]" ? "0" : "[580px]");
        setCardPaddingTop(cardPaddingTop === "0" ? "0" : "0");
        setCardPaddingBottom(cardPaddingBottom === "0" ? "0" : "0");
    }

    const toggleCard = () => {
        setIsCardOpen(!isCardOpen);
        toggleCardHeight();
    };

    const confirmDelete = (precode, ticketid, fanocard) => {
        if (window.confirm(`${t("seisicurodivolereliminarequestacarta")}`)) {
            deleteCard(precode, ticketid, fanocard);
        }
    };



    const [totalActivations, setTotalActivations] = useState(0);
    const totalBusServiceActive = async (card) => {
        setIsLoading(true);
        setIsError(false);

        // Determina quale dato è attivo e assegna il valore corrispondente
        const auth = {
            username: 'rest.serviziobus',
            password: 'vGhQdQq!WtnVCh5nLgJ6fXP!'
        };

        const headers = {
            'Authorization': `Basic ${btoa(`${auth.username}:${auth.password}`)}`,
            'test': 'test'
        };

        try {
            const response = await axios.get(`https://dev.statistiche.fano.bbsitalia.com/new-api/servizio-bus/check-total/${card.fanocard ? card.fanocard : card.tiketid ? card.tiketid : card.precode}?_format=json`, {headers});
            const data = response.data;
            setTotalActivations(data.length);
            // console.log(totalActivations)
        } catch (error) {
            // console.error('Error:', error);
            setIsError(true);
        }
        setIsLoading(false);
    };


    const [serviceActiveCardInformation, setServiceActiveCardInfomation] = useState([]);

    const activeCardInformation = async (card) => {
        const auth = {
            username: 'rest.serviziobus',
            password: 'vGhQdQq!WtnVCh5nLgJ6fXP!'
        };

        const headers = {
            'Authorization': `Basic ${btoa(`${auth.username}:${auth.password}`)}`,
            'test': 'test'
        };
        axios.get(`https://dev.statistiche.fano.bbsitalia.com/new-api/servizio-bus/${card.fanocard ? card.fanocard : card.tiketid ? card.tiketid : card.precode}`, {headers})
            // axios.get(`https://dev.statistiche.fano.bbsitalia.com/new-api/servizio-bus/${cardName}`)
            .then(response => {
                setServiceActiveCardInfomation(response.data)
                // console.log(serviceActiveCardInformation)
            })
            .catch(error => console.log(error));

    }
    const openExternalLink = (url, event) => {
        if (window.cordova) {
            event.preventDefault();
            window.cordova.InAppBrowser.open(url, "_system");
        } else {
            event.preventDefault();
            window.open(url, "_blank");
        }
    };

    const normalizeProvenienza = (provenienza) => {
        // Rimuove spazi, trattini, slash, & e sostituisce i "+" con "e"
        return provenienza.replace(/\s|[-/&]/g, '').replace(/\+/g, 'e').toLowerCase();
    };

    const isPesaroUrbino = (provenienza) => {
        const normalized = normalizeProvenienza(provenienza);
        const validForms = [
            'pesarourbino',
            'pesaroeurbino',
            'pesarouribno',
            'pesarourino',
            'pesarourbiono',
            'pu',
            'p.u.',
            'p/u',
            'p-u',
            '61100'  // Aggiungi codici postali o numeri che potrebbero essere usati come identificatori
        ];
        return validForms.includes(normalized);
    };

    return (
        <div className="card flex flex-wrap justify-start items-start overflow-hidden overflow-x-hidden">
            {dataCard.map((item, index) => {
                const startDate = new Date(
                    item.data_inizio.substring(0, 4),
                    item.data_inizio.substring(4, 6) - 1,
                    item.data_inizio.substring(6)
                );
                const endDate = new Date(
                    item.data_fine.substring(0, 4),
                    item.data_fine.substring(4, 6) - 1,
                    item.data_fine.substring(6)
                );
                const isActive = today >= startDate && today <= endDate;
                const statusText = isActive ? `${t('attiva')}` : `${t('nonattiva')}`;
                const statusTextBus = isActive ? `${t('busattivo')}` : `${t('busnonattivo')}`;
                const statusColor = isActive ? "bg-green-500" : "bg-red-500";
                const cardImage = item.id_tipologia === "57" ? fanoCard7gg : fanoCard14gg;

                return (
                    <div
                        className="mb-5 text-white font-semibold w-full rounded-[10px] relative cursor-pointer"
                        key={index}
                        onTouchEnd={() => handleCardClick(item)}
                    >
                        <div className="rounded-2xl overflow-hidden backdrop:opacity-5">
                            <img
                                src={cardImage}
                                alt="Sfondo Fano Card"
                                className="h-full w-full rounded-[10px] object-cover"
                            />
                        </div>
                        <div className={'absolute bg-gradient-to-b bg-black bg-opacity-20 top-0 left-0 h-full w-full rounded-[10px]'} />

                        <span className="absolute top-2 right-2 font-semibold bg-fanoBlue px-3 py-1 text-sm rounded-2xl">
              {item.titolare}
            </span>
                        <span className={`absolute bottom-2 left-2 px-3 py-1 text-sm rounded-2xl ${statusColor}`}>
              {statusText}
            </span>
                        {/*<span className={`absolute bottom-10 left-2 px-3 py-1 text-sm rounded-2xl ${statusColor}`}>*/}
              {/*{statusTextBus}*/}
            {/*</span>*/}
                        <span className="absolute bottom-2 right-2 font-semibold bg-fanoBlue px-3 py-1 text-sm rounded-2xl">
              {isActive ? `${Math.ceil((endDate - today) / (1000 * 60 * 60 * 24))} ${t('giornirimanenti')}` : item.activationText}
            </span>
                    </div>
                );
            })}

            {selectedCard && (

                (() => {
                    const startDate = new Date(
                        selectedCard.data_inizio.substring(0, 4),
                        selectedCard.data_inizio.substring(4, 6) - 1,
                        selectedCard.data_inizio.substring(6)
                    );
                    const endDate = new Date(
                        selectedCard.data_fine.substring(0, 4),
                        selectedCard.data_fine.substring(4, 6) - 1,
                        selectedCard.data_fine.substring(6)
                    );
                    const totalDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
                    const remainingDays = Math.ceil((endDate - today) / (1000 * 60 * 60 * 24));
                    const percentage = ((totalDays - remainingDays) / totalDays) * 100;

                    const isActive = today >= startDate && today <= endDate;
                    const statusText = isActive ? `${t('attiva')}` : `${t('nonattiva')}`;
                    const statusTextBus = isActive ? `${t('busattivo')}` : `${t('busnonattivo')}`;
                    const statusColor = isActive ? "bg-green-500" : "bg-red-500";

                    return (
                        <div className={`${isCardOpen ? "backdrop:blur-3xl backdrop-brightness-50 backdrop-filter fixed top-0 left-0 w-full h-full z-[9999] flex justify-start items-start opacity-1" : "backdrop:blur-3xl backdrop-brightness-50 backdrop-filter fixed top-0 left-0 w-full z-[9999] flex justify-start items-start opacity-0"} `}>
                            {/*<div className={`${isCardOpen ? "backdrop:blur-3xl backdrop-brightness-50 backdrop-filter fixed top-0 l1eft-0 w-full h-full z-[9999] flex justify-start items-start" : "opacity-0"} `} onTouchEnd={toggleCard}>*/}
                            <div
                                className={`${
                                    isCardOpen ? "" : "hidden"
                                } fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-[9999]`}
                                id="my-modal"
                                onTouchEnd={toggleCard}
                            ></div>
                            {/*<div className={`${isCardOpen ? "bg-white " : "bg-fanoBlue delay-300"} absolute w-full pb-10 bottom-0 overflow-scroll  b-t-l-r-15 pl-5 pr-5 z-[9999]`}>*/}
                            <div className={`${isCardOpen ? "bg-white " : "bg-fanoBlue delay-300"} relative h-full w-full pb-10 bottom-0 pl-5 pr-5 z-[9999]`}>
                                {/*<div className={`h-${cardHeight} top-${cardTop} z-10 relative duration-300 pt-${cardPaddingTop} pb-${cardPaddingBottom} w-full`}>*/}
                                <div className={`h-${cardHeight} top-${cardTop} z-10 relative duration-300 pt-${cardPaddingTop} pb-${cardPaddingBottom} w-full`}>
                                    <div className={'h-[8vh] pt-3'}>
                                        <div className=" flex flex-wrap items-start w-full justify-start pt-4 pb-4" onTouchEnd={toggleCard}>
                                            <img src={arrowDown} className="ml-1 mr-3 cursor-pointer"  />
                                            <p className="font-semibold text-left -mt-1">
                                                {/*{selectedCard.sottotipo}*/}
                                                {t('fanovisitcard')}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="h-[73vh] overflow-scroll">
                                        <div className="text-left w-full">
                                        {selectedCard.precode ? (
                                            <div className="flex flex-wrap justify-center items-center w-full">
                                                <QRCode value={selectedCard.precode} size={175} />
                                                {/*<p className="text-sm w-full text-center mt-2">{selectedCard.precode}</p>*/}
                                            </div>
                                        ) : selectedCard.tiketid ? (
                                            <div className="flex flex-wrap justify-center items-center w-full">
                                                <QRCode value={selectedCard.tiketid} size={175} />
                                                {/*<p className="text-sm w-full text-center mt-2">{selectedCard.tiketid}</p>*/}
                                            </div>
                                        ) : selectedCard.fanocard ? (
                                            <div className="flex flex-wrap justify-center items-center w-full">
                                                <QRCode value={selectedCard.fanocard} size={175} />
                                                {/*<p className="text-sm w-full text-center mt-2">{selectedCard.fanocard}</p>*/}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="mt-5 text-start w-full">
                                        <div className="w-full">
                                            <div className="mt-3 w-full border-b pt-5">
                                                <div className={'flex justify-between flex-wrap'}>
                                                    <p className={'font-semibold text-[16px] mb-3'}>La tua card</p>
                                                    <span
                                                        className={`relative px-3 h-6 text-[14px] rounded-2xl text-white ${statusColor}`}>
                                                        {statusText}
                                                    </span>

                                                </div>
                                                <div className={'flex justify-between flex-wrap'}>
                                                    <p className={'mt-2 mb-2 text-sm'}>
                                                        Codice:
                                                    </p>
                                                    <p className={'text-sm mt-2 mb-2'}>
                                                        {selectedCard.precode ? selectedCard.precode : selectedCard.tiketid ? selectedCard.tiketid : selectedCard.fanocard}
                                                    </p>
                                                </div>
                                                <div className={'flex justify-between flex-wrap'}>
                                                    <p className={'mt-2 mb-2 text-sm'}>
                                                        {t("scadetra")}{" "}
                                                    </p>
                                                    <p className={'text-sm mt-2 mb-2'}>
                                                        {Math.max(
                                                            0,
                                                            Math.ceil(
                                                                (new Date(
                                                                        selectedCard.data_fine.substring(0, 4),
                                                                        selectedCard.data_fine.substring(4, 6) - 1,
                                                                        selectedCard.data_fine.substring(6)
                                                                    ) -
                                                                    today) /
                                                                (1000 * 60 * 60 * 24)
                                                            )
                                                        )}{" "}
                                                        {t("giorniscade")}
                                                    </p>

                                                </div>
                                                <div className={'flex justify-between flex-wrap'}>
                                                    <p className={'mt-2 mb-2 text-sm'}>
                                                        Provenienza:
                                                    </p>
                                                    <p className={'text-sm mt-2 mb-2'}>
                                                        {selectedCard.provenienza}
                                                    </p>
                                                </div>
                                                <div className={'flex justify-between flex-wrap'}>
                                                    <p className={'mt-2 mb-2 pb-3 text-sm'}>
                                                        Intestatario:
                                                    </p>
                                                    <p className={'text-sm mt-2 mb-2'}>
                                                        {selectedCard.titolare}
                                                    </p>
                                                </div>
                                            </div>
                                            {!isPesaroUrbino(selectedCard.provenienza) && (
                                                <div className={'mt-3 w-full border-b pb-5'}>
                                                    <div className={'flex justify-between flex-wrap'}>
                                                        <p className={'font-semibold text-[16px] mb-3'}>Servizi bus</p>
                                                        {totalActivations === 0 ? (
                                                            <>
                                                            </>
                                                        ) : (
                                                            <>
                                                            </>
                                                        )}
                                                    </div>
                                                    <ServizioBus
                                                        cardActive={isActive}
                                                        codeFano={selectedCard.fanocard ? selectedCard.fanocard : selectedCard.tiketid ? selectedCard.tiketid : selectedCard.precode}
                                                        information={serviceActiveCardInformation[0]}
                                                        serviziAttivati={serviceActiveCardInformation.length}
                                                        dataServizioBus={{
                                                            precode: selectedCard.precode,
                                                            fanocard: selectedCard.fanocard,
                                                            tiketid: selectedCard.tiketid,
                                                            titolare: selectedCard.titolare,
                                                            email: selectedCard.email ? selectedCard.email : selectedCard.email_utente,
                                                            provenienza: selectedCard.provenienza,
                                                            data_fine: selectedCard.data_fine
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    </div>
                                    <div className={'h-[20vh] bg-white overflow-scroll'}>

                                        <a className={'w-full '} href={'https://visitfano.info/fanovisitcard/'}
                                           onClick={(e) => openExternalLink('https://visitfano.info/fanovisitcard/', e)}>
                                            <button
                                                className={'w-full border border-fanoBlue bg-white text-fanoBlue px-3 py-2 rounded-md font-medium h-[42px] mb-3 mt-2 rounded-[10px] w-full'}>{t('serviziinclusicart')}</button>
                                        </a>
                                        <butto
                                            className={'w-full bg-red-500 text-white px-3 py-2 rounded-md font-medium rounded-[10px] w-full border border-red-500 h-[42px]'}
                                            onTouchEnd={() => confirmDelete(selectedCard.precode, selectedCard.tiketid, selectedCard.fanocard)}>{t('rimuovicard')}</butto>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })()
            )}
        </div>
    );
};
