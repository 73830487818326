import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "../api";
import { Link } from "react-router-dom";
import doveDormireWhite from "../../assets/icons/doveDormireWhite.svg";
import BarLoader from "react-spinners/BarLoader";
import { useMediaQuery } from "react-responsive";
import { BackButton } from "../UI/BackButton";
import { BackButtonWhite } from "../UI/BackButtonWhite";
export const EventiList = ({ eventi }) => {
  const { id } = useParams();

  const currentLanguage = localStorage.getItem("i18nextLng");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10; // numero di elementi per pagina
  const eventsPerPage = 50; // limite massimo di eventi da recuperare per ogni chiamata all'API
  const [hasMoreData, setHasMoreData] = useState(true);

  const [titoloRassegna, setTitoloRassegna] = useState("");
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `https://backend.fano.bbsitalia.com/${currentLanguage}/api/correlati/eventi/${id}`
      )
      .then((response) => {
        console.log(response.data);

        setData(response.data);
        setTitoloRassegna(response.data[0].titolo_rassegna);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  const isDesktop = useMediaQuery({
    query: "(min-width: 1024px)",
  });
  return (
    <div className={"overflow-auto h-full"}>
      <div
        className={
          "flex flex-wrap h-[16vh] content-center relative z-10 p-5 bg-fanoBlue w-full"
        }
      >
        <div className={"flex flex-wrap items-center"}>
          {!isDesktop && <BackButton sfondo={"false"} />}
          {isDesktop ? (
            <span className={"text-white font-semibold text-[40px] ml-4"}>
              {titoloRassegna}
            </span>
          ) : (
            <span className={"text-white font-semibold text-[20px] ml-4"}>
              {titoloRassegna}
            </span>
          )}
        </div>
      </div>
      <div
        className={
          "mt-5 w-full px-5 justify-start content-start align-top h-[73vh] overflow-auto  "
        }
      >
        {data?.map((doveDormireList, i) => (
          <div key={i} className={"w-full rounded-[10px] p-2 mb-5"}>
            <Link
              to={`/eventi-e-rassegne/${doveDormireList.id}`}
              className={"w-full"}
            >
              <div
                className={
                  "shadow-fanoShadow rounded-[10px] w-full relative h-full"
                }
              >
                <div
                  className={
                    "absolute bg-fanoBlue top-0 right-0 p-2 rounded-[10px]"
                  }
                >
                  {isDesktop ? (
                    <img src={doveDormireWhite} className={"w-10 h-10"} />
                  ) : (
                    <img src={doveDormireWhite} className={"w-6 h-6"} />
                  )}
                </div>
                <img
                  src={`https://backend.fano.bbsitalia.com${doveDormireList.immagine_anteprima}`}
                  className={
                    "w-full h-[250px] lg:h-[28rem] object-cover rounded-[10px]"
                  }
                />
                <div
                  className={
                    "flex flex-wrap justify-center items-center px-4 py-2"
                  }
                >
                  {isDesktop ? (
                    <h1
                      className={
                        "text-fanoBlue text-[25px] font-roboto_slab font-bold pt-3 pb-3"
                      }
                    >
                      {doveDormireList.titolo}
                    </h1>
                  ) : (
                    <h1
                      className={
                        "text-fanoBlue text-[15px] font-roboto_slab font-bold pt-3 pb-3"
                      }
                    >
                      {doveDormireList.titolo}
                    </h1>
                  )}
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
      {isDesktop ? (
        <div
          className={
            "bg-fanoBlue h-[10vh] b-t-l-r-15 content-center flex flex-wrap items-center p-8"
          }
        >
          <BackButtonWhite sfondo={"true"} />
        </div>
      ) : (
        <div className={"hidden"} />
      )}{" "}
      {isLoading && (
        <div className="flex justify-center items-center h-44">
          <BarLoader color={"#2b6cb0"} size={25} />
        </div>
      )}
    </div>
  );
};
