import React, { useRef, useState } from "react";
import AFrame from "aframe";
import styles from "./Video360Component.module.css";

import play from "../../assets/icons/play.svg";
import pause from "../../assets/icons/pause.svg";

import { Entity, Scene } from "aframe-react";
import "react-aframe-ar";
const Video360Component = ({ videoUrl }) => {
  const [verticalMovement, setVerticalMovement] = useState(0);

  /*   const handleKeyDown = (e) => {
    if (e.key === "ArrowUp") {
      setVerticalMovement(verticalMovement + 0.1);
    } else if (e.key === "ArrowDown") {
      setVerticalMovement(verticalMovement - 0.1);
    }
  }; */

  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [isSeeking, setIsSeeking] = useState(false);

  const handlePlayPause = (e) => {
    e.stopPropagation();
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleSeek = (e) => {
    if (isSeeking) {
      const seekTime =
        (e.nativeEvent.offsetX / e.currentTarget.offsetWidth) *
        videoRef.current.duration;
      setCurrentTime(seekTime);
      videoRef.current.currentTime = seekTime;
    }
  };

  return (
    <Scene>
      <a-assets>
        <video
          onCanPlay={() => {
            console.log(
              "Video can start, but not sure if it will play through"
            );
          }}
          onCanPlayThrough={() => {
            console.log("Video can play through without interruption");
          }}
          onStalled={() => {
            console.log("Media playback is stalled!!!");
          }}
          onWaiting={() => {
            console.log("Video is buffering");
          }}
          onPlaying={() => {
            console.log("Video resumed playing after buffering");
          }}
          onError={(e) => {
            console.error("Video error:", e.target.error);
          }}
          id="videoBunny"
          preload="auto"
          src={`${videoUrl}#t=0.1`}
          /*         width="160"
          height="90" */
          crossOrigin="anonymous"
          ref={videoRef}
          onTimeUpdate={() => {
            if (!isSeeking) {
              setCurrentTime(videoRef.current.currentTime);
            }
          }}
        ></video>
      </a-assets>
      <Entity
        styles
        geometry={{
          primitive: "sphere",
          radius: 10,
        }}
        material={{
          shader: "flat",
          src: "#videoBunny",
          side: "double",
        }}
      ></Entity>
      {/* More A-Frame entities */}
      <a-camera
        wasd-controls="acceleration: 150; fly: true"
        look-controls={`reverseMouseDrag: true; verticalSensitivity: 1.5;`}
        position={`0 ${1.6 + verticalMovement} 0`}
      ></a-camera>

      <div className="video-controls z-[9999] fixed left-0 bottom-0 w-full bg-black p-4">
        <div className="video-controls pl-[50px] pr-[50px] flex flex-wrap justify-center items-center w-full">
          <div className="flex w-full justify-center items-center">
            <button
              onClick={handlePlayPause}
              className="inline-block align-middle w-12 h-6 mb-3 text-center relative "
            >
              {isPlaying ? (
                <img
                  src={pause}
                  className={"w-10 h-10 absolute top-0 left-0 z-[9999] block"}
                  alt="Pause"
                />
              ) : (
                <img
                  src={play}
                  className={"w-10 h-10 absolute top-0 left-0 z-[9999] block"}
                  alt="Play"
                />
              )}
            </button>
            <input
              type="range"
              min={0}
              max={videoRef.current?.duration || 0}
              value={currentTime}
              onChange={(e) => {
                setCurrentTime(parseFloat(e.target.value));
                videoRef.current.currentTime = parseFloat(e.target.value);
              }}
              onMouseDown={() => setIsSeeking(true)}
              onMouseUp={() => setIsSeeking(false)}
              className={`${styles.progressBar}`}
            />
          </div>
        </div>
      </div>
    </Scene>
  );
};

export default Video360Component;
