import React, { useState } from "react";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";

export function DateFilter({ events, setFilteredEvents }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleFilterClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFilter = () => {
    const filtered = events.filter((event) => {
      const eventStart = new Date(event.data_inizio);
      const eventEnd = new Date(event.data_fine || event.data_inizio);

      return (
        new Date(startDate) <= eventStart &&
        (!endDate || new Date(endDate) >= eventEnd)
      );
    });
    setFilteredEvents(filtered);
    setIsModalOpen(false);
  };
  const { t } = useTranslation();

  return (
    <div className="date-filter">
      <button
        className={
          "font-semibold font-roboto_slab border border-fanoBlue mb-5 h-[70px] w-[70px] rounded-[10px] text-fanoBlue"
        }
        onClick={handleFilterClick}
      >
        {t("filtra")}
      </button>
      <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal}>
        <h2
          className={
            "w-full font-roboto_slab text-center text-fanoBlue font-semibold text-2xl mb-5"
          }
        >
          {t("filtraperdata")}
        </h2>
        <label
          className={
            "w-full text-center text-fanoBlue block font-semibold mb-2"
          }
        >
          {t("datainizio")}:
        </label>
        <input
          className={
            "block text-fanoBlue m-auto border-2 border-fanoBlue px-5 py-2 rounded-2xl mb-5"
          }
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <label
          className={
            "w-full text-center text-fanoBlue block font-semibold mb-2"
          }
        >
          {t("datafine")}:
        </label>
        <input
          className={
            "block text-fanoBlue m-auto border-2 border-fanoBlue px-5 py-2 rounded-2xl"
          }
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
        <button
          className={
            "p-2 bg-fanoBlue text-white rounded-[10px] font-semibold mb-5 flex font-roboto_slab m-auto mt-5 text-center"
          }
          onClick={handleFilter}
        >
          {t("applicafiltro")}
        </button>
        <button
          className={
            "p-2 bg-fanoBlue text-white rounded-[10px] font-semibold mb-5 flex font-roboto_slab m-auto text-center"
          }
          onClick={handleCloseModal}
        >
          {t("chiudi")}
        </button>
      </Modal>
    </div>
  );
}
