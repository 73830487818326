import React, { useEffect, useState } from "react";
import GalleryItems from "./GalleryItems";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";
import axios from "../api";

const Gallery = (props) => {
    const currentLanguage = localStorage.getItem("i18nextLng");

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        axios
            .get(
                `https://backend.fano.bbsitalia.com/${currentLanguage}/api/media/galleria/${props.images}`
            )
            .then((response) => {
                setData(response.data);
                setIsLoading(false);
            })
            .catch((error) => console.log(error));
    }, []);

    useEffect(() => {
        let lightbox = new PhotoSwipeLightbox({
            gallery: "#gallery",
            children: "a",
            pswpModule: () => import("photoswipe"),
        });

        lightbox.on("close", () => {
            // console.log('close');
        });
        lightbox.init();
        return () => {
            lightbox.destroy();
            lightbox = null;
        };
    }, []);

    return (
        <div id="gallery" className="pswp-gallery w-full lg:px-0 lg:-ml-10 mt-5">
            <div className="relative w-full flex gap-6 snap-x snap-mandatory overflow-x-auto pb-14">
                <div className="snap-end scroll-mx-6 shrink-0 hidden lg:block">
                    <div className="shrink-0 w-3 lg:w-5 md:w-5  sm:w-10 sm:-mr-[2px]"></div>
                </div>
                {data.map((item, i) => (
                    <div className="snap-end scroll-mx-6 shrink-0 w-72" key={i}>
                        <GalleryItems src={item.field_media_image_export} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Gallery;
