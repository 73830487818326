import React, { useEffect, useState } from "react";
import hamburgerMenuImage from "../../assets/icons/menu.svg";
import backButtonImage from "../../assets/icons/backArrow.svg";
import { Link } from "react-router-dom";
import axios from "../api";
import fanoLogo from "../../assets/fano.png";
import bgSfondoHamburger from "../../assets/bgSfondoHamburger.png";

function HamburgerMenu() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [data, setData] = useState(null);

    function toggleMenu() {
        setIsMenuOpen(!isMenuOpen);
    }

    useEffect(() => {
        const currentLanguage = localStorage.getItem("i18nextLng");
        axios
            .get(`https://backend.fano.bbsitalia.com/${currentLanguage}/api/menu_hamburger`)
            .then((response) => setData(response.data))
            .catch((error) => console.log(error));
    }, []);
    const openExternalLink = (url, event) => {
        if (window.cordova) {
            event.preventDefault();
            window.cordova.InAppBrowser.open(url, "_system");
        } else {
            event.preventDefault();
            window.open(url, "_blank");
        }
    };

    return (
        <div className="relative">
            <button className="rounded-md text-white focus:outline-none" onClick={toggleMenu}>
                <img src={hamburgerMenuImage} alt={"hamburgerMenuImage"} className={"w-10 h-10"} />
            </button>
            <div
                className={`${isMenuOpen ? "block" : "hidden"} fixed top-0 left-0 w-full h-full z-50 bg-gray-800 opacity-75`}
                onClick={toggleMenu}
            ></div>
            <div
                className={`${
                    isMenuOpen ? "translate-x-0" : "-translate-x-full"
                } fixed text-left top-0 left-0 w-full h-full z-50 bg-white shadow-lg transform transition-all duration-300 ease-in-out`}
            >
                <img src={bgSfondoHamburger} alt={bgSfondoHamburger} className={"absolute top-0 left-0 w-full h-screen object-cover z-0"} />

                <div className={"inset-0 bg-black bg-opacity-60 flex justify-start items-center pl-5 pr-5 absolute top-0 left-0 w-full h-screen"} />
                <div className={"flex flex-wrap h-[16vh] content-center relative z-10 p-8"}>
                    <div className={"flex flex-wrap"}>
                        <button onClick={toggleMenu}>
                            <img src={backButtonImage} alt={"backButtonImage"} className={"w-5 h-5"} />
                        </button>
                    </div>
                    <img className={"w-[150px] h-auto m-auto"} src={fanoLogo} />
                </div>

                <div className="py-5 px-8 relative z-10">
                    {data &&
                        data.map((item, index) => {
                            const isExternalLink = item.url.startsWith("http") || item.url.startsWith("https");

                            return isExternalLink ? (
                                <a
                                    key={index}
                                    className="block text-white font-semibold py-2 hover:underline text-left font-roboto_slab text-[23px] mb-5"
                                    href={item.url}
                                    onClick={(e) => openExternalLink(item.url, e)}
                                >
                                    {item.titolo}
                                </a>
                            ) : (
                                <Link
                                    key={index}
                                    className="block text-white font-semibold py-2 hover:underline text-left font-roboto_slab text-[23px] mb-5"
                                    to={item.url}
                                >
                                    {item.titolo}
                                </Link>
                            );
                        })}
                </div>
            </div>
        </div>
    );
}

export default HamburgerMenu;
